import React, { useRef } from 'react'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import UserService from 'UserService'
import { ListSizeFooter } from 'assets/AssetsList'
import { CustomDateField, CustomLinkField, GatedCommunitySearchFilter, LanguageFilter } from 'common'
import { languages } from 'common/dictonary'
import { Button, DatagridConfigurable, Edit, FilterButton, SelectColumnsButton, List, SelectInput, SimpleForm, TextField, TextInput, TopToolbar, useRecordContext, useResourceContext, CreateButton, Create, required, useEditContext, ListButton, Toolbar, SaveButton, DeleteWithConfirmButton, AutocompleteArrayInput, ReferenceArrayInput, FunctionField } from 'react-admin'
import { getOptionsSelectFromArray, parseTimestamp } from 'utils/helpers'
import { validateTaxonomy } from 'company/showTabs/companyModals/EditPortalProperties'
import { stripCategoryPrefix } from 'company/showTabs/PortalProperties'

const SINGLE_PAGE_LIMIT = 100
const DICT_LANG = 'dict.lang.'

const transform = (data: any) => ({
  ...data,
  taxonomyKey: `Category:${stripCategoryPrefix(data.taxonomyKey)}`,
  sourceText: data.sourceText && data.sourceText.trim(),
  targetText: data.targetText && data.targetText.trim(),
  comments: data.comments && (data.comments.trim() === '' ? null : data.comments.trim())
})

const requiredNotBlank = (message = 'Provide not blank value') => (value: string | null) => {
  return (value && value.trim() !== '') ? undefined : message    
}

const checkTaxonomy = (sourceRef: any, message = 'Provide valid taxonomy') =>
  async (value: string | null) => {
    try {
      if (sourceRef && sourceRef.current) sourceRef.current.cancel()
      const sanitaizedValue = value && value.trim()
      const taxonomy2 = await validateTaxonomy(sanitaizedValue ? stripCategoryPrefix(sanitaizedValue) : null, sourceRef)
      return taxonomy2 ? undefined : message    
    } catch (error) {
      return message
    }
  }

export const SubassetsListDashboardButton = () => <ListButton label="Subassets Dashboard" resource='subassets' />

const subAssetsFilters = [
  <LanguageFilter label="Source lang" source="sourceLang" alwaysOn />,
  <LanguageFilter label="Target lang" source="targetLang" alwaysOn />,
  <TextInput label="Text" source="text" alwaysOn size='medium' />,
  <SelectInput source="searchType" validate={required()} alwaysOn size='medium' sx={{ marginBottom: '0px' }} choices={[
    { id: 'SOURCE_EXACT', name: 'Source text exact match' },
    { id: 'SOURCE_FULL', name: 'Source full text search' },
    { id: 'TARGET_EXACT', name: 'Target text exact match' },
    { id: 'TARGET_FULL', name: 'Target full text search' },
    { id: 'ANY_EXACT', name: 'Any text exact match' },
    { id: 'ANY_FULL', name: 'Any full text search' }
  ]} />,
  <GatedCommunitySearchFilter source='gatedCommunities' alwaysOn />,
  <SelectInput source="type" alwaysOn size='medium' sx={{ marginBottom: '0px' }} choices={[
    { id: 'TERM', name: 'Term' },
    { id: 'FORBIDDEN', name: 'Forbidden' }
  ]} />,
  <SelectInput source="status" alwaysOn size='medium' sx={{ marginBottom: '0px' }} choices={[
    { id: 'ACTIVE', name: 'Active' },
    { id: 'DELETED', name: 'Deleted' }
  ]} />,
  <SelectInput source="creationSource" size='medium' sx={{ marginBottom: '0px' }} choices={[
    { id: 'ASSET_MANAGER', name: 'Asset manager' },
    { id: 'BATCH_IMPORT', name: 'Batch import' },
    { id: 'WORKFLOW', name: 'Workflow' }
  ]} />
]

const showTimeInTimezone = (time: string | null | undefined) => {
  const { timezone } = UserService.getTokenUserInfo()
  return <span>{parseTimestamp(time, timezone, true)}</span>
}

const SubAssetHistory = () => {
  const { record } = useEditContext()
  return (
    (record.subAssetHistory && record.subAssetHistory.length > 0) ? <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650, backgroundColor: '#f5f5f5' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">Source lang</TableCell>
            <TableCell align="right">Target lang</TableCell>
            <TableCell align="right">Source text</TableCell>
            <TableCell align="right">Target text</TableCell>
            <TableCell align="right">Comments</TableCell>
            <TableCell align="right">Creator</TableCell>
            <TableCell align="right">Creation date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record.subAssetHistory.map((row: any) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: (record.id === row.id ? '#e6e6e6' : null) }}
            >
              <TableCell component="th" scope="row">{row.id}</TableCell>
              <TableCell align="right">{row.langFrom}</TableCell>
              <TableCell align="right">{row.langTo}</TableCell>
              <TableCell align="right">{row.sourceText}</TableCell>
              <TableCell align="right">{row.targetText}</TableCell>
              <TableCell align="right">{row.comments}</TableCell>
              <TableCell align="right">{row.creator}</TableCell>
              <TableCell align="right">{showTimeInTimezone(row.creationDate)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> : <Typography sx={{ font: 'normal normal bold 20px/25px Inter' }}>There is no subasset history</Typography>
  )
}

const EditToolbar = () => {
  return <Toolbar sx={{ justifyContent:'space-between' }}>
    <SaveButton/>
    <DeleteWithConfirmButton confirmContent="Status of subasset will be change to DELETED. Are you sure?"/>
  </Toolbar>
}

const SubAssetEdit = () => {
  const record = useRecordContext()
  const resource = useResourceContext()
  const sourceRef: any = useRef(null)
  return (<>
    <Edit
      resource={resource}
      id={record.id}
      /* disable the app title change when shown */
      title=" "
      transform={transform}
    >
      <>
        {record.status == 'ACTIVE' && <SimpleForm mode="onChange" reValidateMode='onChange' toolbar={<EditToolbar />}>
          <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput source="langFrom" choices={getOptionsSelectFromArray(languages, DICT_LANG)} validate={[required()]} sx={{ width: '223px' }}/>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput source="langTo" choices={getOptionsSelectFromArray(languages, DICT_LANG)} validate={[required()]} sx={{ width: '223px' }}/>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="sourceText" validate={[required(), requiredNotBlank()]} sx={{ marginTop: '8px' }}/>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="targetText" validate={[required(), requiredNotBlank()]} sx={{ marginTop: '8px' }}/>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="comments" sx={{ marginTop: '8px' }}/>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="taxonomyKey" label="Taxonomy" sx={{ marginTop: '8px' }} 
                format={value => value === null ? '' : stripCategoryPrefix(value)} validate={[ checkTaxonomy(sourceRef) ]} />
            </Box>
          </Box>
        </SimpleForm>}
        <SubAssetHistory />
      </>
    </Edit>
  </>
  )
}

const filterToQuery = (searchText: string) => ({ name: searchText })

export const SubAssetCreate = () => {
  const sourceRef: any = useRef(null)

  return <Create title='Create Subasset' transform={transform} actions={<TopToolbar><SubassetsListDashboardButton /></TopToolbar>}>
    <SimpleForm mode="onChange" reValidateMode='onChange'>
      <SelectInput source="langFrom" label='Source lang' choices={getOptionsSelectFromArray(languages, DICT_LANG)} sx={{ width: '223px' }} validate={[required()]}/>
      <SelectInput source="langTo" label='Target lang' choices={getOptionsSelectFromArray(languages, DICT_LANG)} sx={{ width: '223px' }} validate={[required()]}/>
      <TextInput source="sourceText" validate={[required(), requiredNotBlank()]} />
      <TextInput source="targetText" validate={[required(), requiredNotBlank()]} />
      <TextInput source="comments" multiline={true} sx={{ width: '223px' }}/>
      <SelectInput source="type" choices={[{ id: 'TERM', name: 'Term' }, { id: 'FORBIDDEN', name: 'Forbidden' }]} sx={{ width: '223px' }} validate={[required()]} />
      <TextInput source="taxonomyKey" label="Taxonomy" validate={[checkTaxonomy(sourceRef)]} />
      <ReferenceArrayInput source="gatedCommunities" reference="gatedCommunities/search">
        <AutocompleteArrayInput optionText="name" filterToQuery={filterToQuery} size='small' sx={{ minWidth: '223px' }} validate={[required()]}/>
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
}

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <CreateButton />
    <Button label='IMPORT' href={'#/subassets/import'} />
    {/* <ExportButton /> */}
  </TopToolbar>
)

const SubAssetsList = () => {
  return <List actions={<ListActions />} 
    perPage={SINGLE_PAGE_LIMIT} 
    pagination={<ListSizeFooter />} 
    filters={subAssetsFilters} 
    filterDefaultValues={{ searchType: 'SOURCE_FULL', type: 'TERM', status: 'ACTIVE' }}
    sort={{ field: 'SOURCE_TEXT', order: 'ASC' }}
  >
    <DatagridConfigurable
      expand={<SubAssetEdit />} 
      bulkActionButtons={false}
    >
      <TextField source='id' sortable={false} />
      <TextField source='langFrom' label={'Source lang'} sortBy={"LANGUAGE_FROM"} />
      <TextField source='langTo' label={'Target lang'} sortBy={"LANGUAGE_TO"} />
      <TextField source='sourceText' label={'Source text'} sortBy={"SOURCE_TEXT"} /> 
      <TextField source='targetText' label={'Target text'} sortBy={"TARGET_TEXT"} />
      <TextField source='comments' label={'Comments'} sortable={false} />
      <TextField source='type' sortable={false} />
      <FunctionField source="taxonomyKey" label={'Taxonomy'} sortable={false} 
        render={(record: { taxonomyKey: any }) => record.taxonomyKey && stripCategoryPrefix(record.taxonomyKey)}/>
      <CustomLinkField source="creator" label={'Creator'} sortable={false} basePath='users' show targetFieldId='creator' />
      <CustomDateField source='creationDate' label={'Creation date'} sortable={true} sortBy='CREATE_DATE' />
      <TextField source='creationSource' label={'Creation source'} sortable={false} />
      <TextField source='status' sortable={false} />
    </DatagridConfigurable>
  </List>
}

export default React.memo(SubAssetsList)